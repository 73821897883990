.navbar {
  background-color: #4caf50c9;
  overflow: hidden;
  height: "5vh";
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  /* 使用 flexbox 布局 */
}

.nav-item {
  padding: 0px 0px;
}

.nav-item a {
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  display: block;
}

.nav-item a:hover {
  background-color: #ddd;
  color: black;
  border-radius: 20px;
}